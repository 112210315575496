import * as React from 'react'

import Layout from '../../components/Layout'
import PageHeader from '../../components/PageHeader'
import BlogRoll from '../../components/BlogRoll'

export default class BlogIndexPage extends React.Component {
  render() {
    const title = 'Our Blog'
    const subheading = 'It starts right here... at home!'

    return (
      <Layout>
        <PageHeader title={title} subheading={subheading} />
        <section className="section">
          <div className="container">
            <div className="content">
              <BlogRoll />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}
